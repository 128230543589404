<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<template>
  <div>
    <!-- <img src="img/ketang.png" class="wximg" /> -->
    <!-- 动态数据页面 -->
    <!-- <img src="https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2023-09-09/8e5fd417a6fd9a4637f829e5810bc62a74a4.jpg" class="wximg" /> -->
    <!-- 我的健康状态页面 -->
    <h5 class="h5">绑定成功</h5>
    <img
      src="https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2024-12-28/b7d583d9970f6a4c1c182b89936bfb167db9.png"
      class="wximg"
    />
    <!-- <div class="gotext" @click="goApp()">点击跳转</div> -->
  </div>
</template>

<script>

export default {
  name: 'kindex',
  data () {
    return {
    }
  },
}
</script>

<style>

.wximg {
  position: fixed;
  width: 30%;
  left: 35%;
  top: 30%;
}

.h5 {
  position: fixed;
  left: 43%;
  font-size: 100%;
  top: 15%;
}
</style>
